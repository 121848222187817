import { Paper } from '@material-ui/core';
import React from 'react';
import { Paragraph } from '../common/atoms/Paragraph';
import { StyledPageContainer } from '../common/atoms/StyledPageContainer';
import { Title } from '../common/atoms/Title';

export const FrontPage = () => (
  <StyledPageContainer>
    <Paper elevation={2}>
      <Title titleKey="navigation.home" />
      <Paragraph
        description="Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum
        Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum
        Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum
        Lirum larum Lirum larum Lirum larum"
      />
    </Paper>
  </StyledPageContainer>
);
