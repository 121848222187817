import React from 'react';
import { StyledPageContainer } from '../common/atoms/StyledPageContainer';
import { Title } from '../common/atoms/Title';

export const LinksPage = () => (
  <StyledPageContainer>
    <Title titleKey="navigation.links" />
    <ul>
      <li>
        <a href="https://mpk.raccna.fi/" target="_blank" rel="noreferrer">
          MPK-kurssilistaus
        </a>
      </li>
      <li>
        <a href="https://www.tres.fi/" target="_blank" rel="noreferrer">
          Tampereen Reserviläiset
        </a>
      </li>
    </ul>
  </StyledPageContainer>
);
