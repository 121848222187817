import React from 'react';
import { Paragraph } from '../common/atoms/Paragraph';
import { StyledPageContainer } from '../common/atoms/StyledPageContainer';
import { Title } from '../common/atoms/Title';

export const ProjectPage = () => (
  <StyledPageContainer>
    <Title titleKey="navigation.project" />
    <Paragraph
      description="Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum
        Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum
        Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum Lirum larum
        Lirum larum Lirum larum Lirum larum"
    />
  </StyledPageContainer>
);
