import React from 'react';
import { AppBar, Button, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { State } from './types/State';

type Props = {
  state: State;
  setState: (state: State) => void;
};

export const Header = (props: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { state, setState } = props;

  const navigateTo = (newPath: string) => {
    setState({
      ...state,
      path: newPath,
    });
    navigate(newPath);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Button color={selectColor(state, '/')} onClick={() => navigateTo('/')}>
          {intl.formatMessage({ id: 'navigation.home' })}
        </Button>
        <Button
          color={selectColor(state, '/about')}
          onClick={() => navigateTo('/about')}
        >
          {intl.formatMessage({ id: 'navigation.about' })}
        </Button>
        <Button
          color={selectColor(state, '/project')}
          onClick={() => navigateTo('/project')}
        >
          {intl.formatMessage({ id: 'navigation.project' })}
        </Button>
        <Button
          color={selectColor(state, '/shooting')}
          onClick={() => navigateTo('/shooting')}
        >
          {intl.formatMessage({ id: 'navigation.shooting_hobby' })}
        </Button>
        <Button
          color={selectColor(state, '/links')}
          onClick={() => navigateTo('/links')}
        >
          {intl.formatMessage({ id: 'navigation.links' })}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const selectColor = (state: State, path: string) =>
  state?.path === path ? 'secondary' : 'inherit';
