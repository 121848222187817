import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const StyledTitle = styled(Typography)`
  padding-top: 1rem;
`;

type Props = {
  titleKey: string;
};

export const Title = (props: Props) => {
  const intl = useIntl();
  const { titleKey } = props;
  return (
    <StyledTitle variant="h5">
      {intl.formatMessage({ id: titleKey })}
    </StyledTitle>
  );
};
