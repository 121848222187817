import React, { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Container, CssBaseline, Box } from '@material-ui/core';
import { AboutPage } from './about/AboutPage';
import { FrontPage } from './frontpage/FrontPage';
import { ProjectPage } from './project/ProjectPage';
import { Header } from './common/Header';
import './App.css';
import { ShootingPage } from './shooting/ShootingPage';
import { LinksPage } from './links/LinksPage';

export const App = () => {
  const location = useLocation();
  const [state, setState] = useState({
    title: 'navigation.home',
    path: location?.pathname,
  });

  return (
    <>
      <CssBaseline />

      <Box className="header">
        <Header state={state} setState={setState} />
      </Box>

      <Container maxWidth="lg">
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/project" element={<ProjectPage />} />
          <Route path="/shooting" element={<ShootingPage />} />
          <Route path="/links" element={<LinksPage />} />
        </Routes>
      </Container>
    </>
  );
};

export default App;
