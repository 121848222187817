import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled(Typography)`
  padding: 1rem;
  border-width: 0.1rem;
  border-color: lightgrey;
  border-style: solid;
  border-radius: 20px;
  margin-top: 1rem;
  margin-right: 1rem;
`;

type Props = {
  description: string;
};

export const Paragraph = (props: Props) => {
  const { description } = props;
  return <Container variant="body2">{description}</Container>;
};
