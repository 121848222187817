import { Paper } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StyledPageContainer } from '../common/atoms/StyledPageContainer';

const StyledPaper = styled(Paper)`
  padding-left: 1.2rem;
`;

export const ShootingPage = () => (
  <StyledPageContainer>
    <StyledPaper elevation={2}>
      <h1>Ammunta harrastuksena?</h1>
      <p>
        Suomessa aseen hallussapito-oikeus perustuu{' '}
        <a
          href="https://www.finlex.fi/fi/laki/ajantasa/1998/19980001"
          target="_blank"
          rel="noreferrer"
        >
          Ampuma-aselakiin.
        </a>{' '}
        Aseen hallussapito edellyttää AINA perusteltua syytä ja oikeus perustuu
        poliisin myöntämään hallussapitolupaan.
      </p>
      <p>
        Aseen toimintatapa on keskeinen tekijä aseen käyttötarkoituksen
        määrittämisessä.{' '}
        <a
          href="https://www.finlex.fi/fi/laki/ajantasa/1998/19980001#a1-1998"
          target="_blank"
          rel="noreferrer"
        >
          Laki
        </a>{' '}
        määrittää aseille seuraavat toimintatavat:
        <ul>
          <li>TT1: lippaaton kertatuli</li>
          <li>TT2: lippaallinen kertatuli</li>
          <li>TT3: itselataava kertatuli</li>
          <li>TT4: sarjatuli</li>
        </ul>
      </p>
      <h2>Aseen hallussapitoluvan hakeminen</h2>
      <p>
        Toistaiseksi aseen hallussapitoluvan haku onnistuu vain jättämällä
        hankkimisluvan hakemus henkilökohtaisesti poliisiasemalle.
      </p>
      <p>
        Seuraavassa yksinkertaistettu, vaiheistettu hakemusprosessi:
        <h3>1. Liity ampumaseuraan</h3>
        <p>
          Liity viralliseen ampumaseuraan (
          <a
            href="https://www.ampumaurheiluliitto.fi/"
            target="_blank"
            rel="noreferrer"
          >
            SAL
          </a>
          ,{' '}
          <a href="https://resul.fi/" target="_blank" rel="noreferrer">
            RESUL
          </a>
          ) ja aloita ampumaharrastus seuran ohjauksessa.
        </p>
        <h3>2. Kartuta harrastuneisuutta</h3>
        <p>
          Osoita harrastuneisuus pitämällä kirjaa harrastuskerroista.
          Päiväkirjoja myyvät useat liikkeet tai vaihtoehtoisesti voit tulostaa{' '}
          <Link to="/files/apk.pdf" target="_blank" download>
            oheisen
          </Link>{' '}
          päiväkirjapohjan. Laki erottelee aseita niiden "vaarallisuuden"
          mukaan, joka on sidottu aseen mittoihin, toimintatapaan sekä
          patruunakapasiteettiin. Eri tyyppisille aseille on erilaiset
          harrastuneisuusvaatimukset:
          <ul>
            <li>Pistooli, 10 kpl / 24kk</li>
            <li>A-luokan kivääri, 5 kpl / 12kk</li>
          </ul>
        </p>
        <p>
          Virallisia{' '}
          <a
            href="https://www.ampumaurheiluliitto.fi/"
            target="_blank"
            rel="noreferrer"
          >
            SAL
          </a>{' '}
          sekä{' '}
          <a
            href="https://resul.fi/lajiesittelyt/"
            target="_blank"
            rel="noreferrer"
          >
            RESUL
          </a>{' '}
          lajeja.
        </p>
        <p>
          Osoitetun harrastuneisuuden tulee tapahtua vastaavanlaisella
          asetyypillä, jota aiot hakea. Esimerkiksi pistoolin pistoolilla ja
          kiväärin kiväärillä.
        </p>
        <h3>3. Hankkimislupa perustelulomakkeen täyttö</h3>
        <p>
          Löydät aseen hankkimisluvan perustelulomakkeen{' '}
          <a
            href="https://poliisi.fi/lomakkeet"
            target="_blank"
            rel="noreferrer"
          >
            Poliisin verkkosivuilta.
          </a>
        </p>
        <p>
          Yleisimmät ovat käytännössä:
          <ul>
            <li>
              <a
                href="https://poliisi.fi/documents/25235045/39994070/Ase+07+fi+-+Perustelulomake+haettaessa+ampuma-aselain+mukaista+lupaa+ampumaurheilu+tai+-harrastus+perusteella.pdf/f37a111b-a2f2-98dc-c7b0-61340d8194a9/Ase+07+fi+-+Perustelulomake+haettaessa+ampuma-aselain+mukaista+lupaa+ampumaurheilu+tai+-harrastus+perusteella.pdf?version=1.3&t=1617173552068"
                target="_blank"
                rel="noreferrer"
              >
                Urheiluammunaperusteinen perustelulomake
              </a>
            </li>
            <li>
              <a
                href="https://poliisi.fi/documents/25235045/39994070/Ase+06+fi+-+Perustelulomake+haettaessa+ampuma-aselain+mukaista+lupaa+mets%C3%A4stysperusteella.pdf/c23bfcfc-39c9-fb4d-781c-787ae871d7e9/Ase+06+fi+-+Perustelulomake+haettaessa+ampuma-aselain+mukaista+lupaa+mets%C3%A4stysperusteella.pdf?version=1.3&t=1617173259551"
                target="_blank"
                rel="noreferrer"
              >
                Metsätysperusteinen perustelulomake
              </a>
            </li>
          </ul>
          Lomakkeella käytännössä selvität:
          <ul>
            <li>Aiemman harrastuneisuutesi hankittavalla aseella</li>
            <li>Mitä lajia aiot harrastaa hankittavalla aseella</li>
            <li>Missä ja miten aiot harrastaa hankittavalla aseella</li>
          </ul>
        </p>
        <h3>4. Hankkimislupa perustelulomakkeen jättö</h3>
        <p>
          Varaa aika haluamallesi poliisiasemalle ja jätä täytetty hakemus
          poliisiasemalle. Aseen hankkimisluvan käsittelymaksu vuonna 2022 on
          105 € (48 € kpl seuraavilta mikäli jätät useamman kerralla). Tarvitset
          hakemuksen liitteeksi:
          <ul>
            <li>
              Harrastuneisuustodistus mikäli lyhyt ase tai A-luokan kivääri
              (seuran AAK)
            </li>
            <li>Harrastuspäiväkirja (kopio)</li>
            <li>SRA-kortti, IPSC-kortti, jne. (kopio)</li>
            <li>Seuran jäsenkortin (kopio)</li>
            <li>Sotilaspassin (kopio)</li>
            <li>
              Huomioi lisäksi aseen{' '}
              <a
                href="https://www.finlex.fi/fi/laki/ajantasa/1998/19980001#L10P106"
                target="_blank"
                rel="noreferrer"
              >
                säilytys
              </a>
              , joka edellyttää lain vaatimia tiloja/sertifioitua asekaappia
            </li>
          </ul>
          Poliisi kutsuu sinut tarvittaessa haastatteluun, jonka jälkeen tekee
          päätöksen.
        </p>
        <h3>5. Aseen hankinta</h3>
        <p>
          Riippuen käsittelyajasta, saat ennen pitkää postitse päätöksen sekä
          hankkimisluvan mikäli hakemuksesi hyväksytään. Hankkimisluvan avulla
          voit hankkia hankkimislupaa vastaavan aseen.
        </p>
        <p>
          Aseen hankkimisen jälkeen käyt esittämässä aseen poliisille, joka
          lähettää sinulle hallussapitoluvan.
        </p>
      </p>
    </StyledPaper>
  </StyledPageContainer>
);
